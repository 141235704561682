import {
  ArrowDropDown,
  ArrowDropUp,
  ExpandLess,
  ExpandMore,
  PeopleOutline,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AccountTypeAPI } from "../../api";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import { useAlert } from "../../lib/alert";
import { AccountType } from "../../model/AccountType";
import { useAtom } from "jotai";
import { accountTypesAtom } from "./state/accountTypesAtom";
import PATHS from "../../components/navigation/_paths";
import OrganizationLogo from "./components/OrganizationLogo";
import useFeature from "../../hooks/useFeature";
import { CreateAccountTypeDialog } from "../accounts";

const SETTINGS_LINKS = [
  PATHS.PROCESSES,
  PATHS.MEMBERS,
  PATHS.ROLES,
  PATHS.EMAIL_FILTERS,
  PATHS.ORGANIZATION_MANAGE,
];

type NavLinkProps = React.PropsWithChildren<{
  dataCy?: string;
  title: string;
  titleIntl?: JSX.Element;
  link?: string;
  onClick?: () => void;
  icon?: JSX.Element;
  sx?: SxProps<Theme>;
  selectedOn?: LinkSelectedOn;
}>;

enum LinkSelectedOn {
  MATCH,
  START,
}

const NavLink = ({
  dataCy,
  title,
  titleIntl,
  link,
  icon,
  children,
  sx,
  onClick,
  selectedOn = LinkSelectedOn.START,
}: NavLinkProps) => {
  const { pathname } = useLocation();
  const linkProps = link
    ? {
        to: link,
        component: Link,
        selected:
          selectedOn === LinkSelectedOn.START
            ? pathname.startsWith(link)
            : pathname === link,
      }
    : {};
  return (
    <ListItemButton
      data-cy={dataCy}
      key={title}
      component={Link}
      onClick={onClick}
      sx={sx}
      {...linkProps}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={titleIntl ?? title}
        sx={{
          "& .MuiTypography-root": { fontWeight: 600, lineHeight: "24px" },
        }}
      />
      {children}
    </ListItemButton>
  );
};

const OrganizationDrawerMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { organization, organizationsLoaded } = useGlobalOrganizationContext();
  const { handleRejectionWithError } = useAlert();
  const [accountTypesOpen, setAccountTypesOpen] = useState(true);
  const [accountTypes, setAccountTypes] = useAtom(accountTypesAtom);
  //const isEnterprise = !!environment.organization?.key;
  const canViewDashboard = useFeature("dashboard");
  const isSettingsRoute = SETTINGS_LINKS.some((sub) => sub.link === pathname);
  const [administrationOpen, setAdministrationOpen] = useState(isSettingsRoute);
  const [createAccountTypeDialogOpen, setCreateAccountTypeDialogOpen] =
    useState(false);

  useEffect(() => {
    if (organization) {
      AccountTypeAPI.byOrganizationId(organization?.id).then(
        (accountTypes: Array<AccountType>) => {
          setAccountTypes(accountTypes);
        },
        handleRejectionWithError,
      );
    }
  }, [organization, handleRejectionWithError, setAccountTypes]);

  if (!organizationsLoaded) {
    return <></>;
  }
  return (
    <Box
      data-cy={"side-drawer"}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "240px",
        height: "100%",
        displayPrint: "none",
        overflowY: "auto",
        px: 2,
        py: 1,
      }}
    >
      <OrganizationLogo
        organization={organization}
        onClick={() => navigate(PATHS.ORGANIZATION_EXECUTIONS.link)}
      />
      <List
        component="nav"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 0.5,
          "& .MuiListItemButton-root": {
            borderRadius: 2,
          },
        }}
      >
        {canViewDashboard && (
          <NavLink sx={{ flex: 0 }} {...PATHS.DASHBOARD}></NavLink>
        )}
        <NavLink
          dataCy="organization-executions-btn"
          sx={{ flex: 0 }}
          onClick={() => setAdministrationOpen(false)}
          {...PATHS.ORGANIZATION_EXECUTIONS}
        ></NavLink>
        <NavLink
          dataCy="accounts-btn"
          sx={{ flex: 0 }}
          {...PATHS.ACCOUNTS}
          selectedOn={LinkSelectedOn.MATCH}
        >
          <IconButton
            onClick={(e) => {
              setAccountTypesOpen((open) => !open);
              e.preventDefault();
            }}
            sx={{ p: 0 }}
          >
            {accountTypesOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </NavLink>
        <Collapse in={accountTypesOpen}>
          <List component="div" disablePadding>
            {accountTypes?.map((accountType) => (
              <ListItemButton
                data-cy="account-type-btn"
                component={Link}
                to={`/user/organization/accounts/byType/${accountType.key}`}
                key={`/user/organization/accounts/byType/${accountType.key}`}
                selected={pathname.startsWith(
                  `/user/organization/accounts/byType/${accountType.key}`,
                )}
                sx={{ flex: 0 }}
                onClick={() => setAdministrationOpen(false)}
              >
                <ListItemText
                  primary={pluralize.plural(accountType.name)}
                  sx={{ ml: 6 }}
                  disableTypography
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItem sx={{ flex: 1 }}></ListItem>
        {administrationOpen && (
          <>
            {SETTINGS_LINKS.filter((sub) => sub.visible).map((sub) => (
              <NavLink
                dataCy={sub.name + "-btn"}
                {...sub}
                key={sub.link}
                sx={{ flex: 0 }}
              />
            ))}
            <NavLink
              title="Create Account Type"
              icon={<PeopleOutline />}
              onClick={() => setCreateAccountTypeDialogOpen(true)}
              sx={{ flex: 0 }}
            ></NavLink>
          </>
        )}
        <NavLink
          title="Administration"
          icon={<Settings />}
          dataCy={"administration-menu-btn"}
          onClick={() => setAdministrationOpen((open) => !open)}
          sx={{ flex: 0 }}
        >
          {administrationOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </NavLink>
      </List>

      {organization && (
        <CreateAccountTypeDialog
          open={createAccountTypeDialogOpen}
          organizationId={organization.id}
          onClose={() => setCreateAccountTypeDialogOpen(false)}
          onSave={(accountType) => {
            const newAccountTypes = accountTypes?.slice();
            newAccountTypes?.push(accountType);
            setAccountTypes(newAccountTypes);

            navigate(`/user/organization/accounts/byType/${accountType.key}`);
          }}
        ></CreateAccountTypeDialog>
      )}
    </Box>
  );
};
export default OrganizationDrawerMenu;
