import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { FC } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

function humanReadable(date: Date) {
  const now = dayjs();
  const inputDate = dayjs(date);
  const diffMinutes = now.diff(inputDate, "minute");
  const diffHours = now.diff(inputDate, "hour");
  const diffDays = now.diff(inputDate, "day");

  if (diffMinutes < 5) {
    return "Just now";
  } else if (diffMinutes < 60) {
    return diffMinutes + "m";
  } else if (diffHours < 24) {
    return diffHours + "h";
  } else if (diffDays === 1) {
    return "Yesterday " + inputDate.format("h:mm A");
  } else if (diffDays < 7) {
    return inputDate.format("ddd h:mm A");
  } else {
    return inputDate.format("MM/DD");
  }
}
function timeOfDay(date: Date) {
  const now = dayjs();
  const inputDate = dayjs(date);
  const diffMinutes = Math.abs(now.diff(inputDate, "minute"));
  const diffDays = Math.abs(now.diff(inputDate, "day"));
  const future = now.diff(inputDate) < 0;

  if (diffMinutes < 5) {
    return "Just now";
  } else if (diffMinutes < 60) {
    return diffMinutes + "m";
  }
  if (now.day() === inputDate.day() && diffDays === 0) {
    return inputDate.format("h:mm A");
  } else if (diffDays < 2) {
    return (
      (future ? "Tomorrow" : "Yesterday") + " " + inputDate.format("h:mm A")
    );
  } else if (diffDays < 8) {
    return inputDate.format("ddd h:mm A");
  } else {
    return inputDate.format("MM/DD h:mm A");
  }
}

const HumanReadableTimestamp: FC<{
  timestamp: Date;
  variant?: TypographyProps["variant"];
  format?: "time" | "short";
}> = ({ timestamp, variant = "body1", format = "short" }) => {
  return (
    <Tooltip title={dayjs(timestamp).format("llll")}>
      <Typography variant={variant} display="inline" component="span">
        {format === "short" ? humanReadable(timestamp) : timeOfDay(timestamp)}
      </Typography>
    </Tooltip>
  );
};

export default HumanReadableTimestamp;
