import { EmailFilter } from "../features/email-filters/model";
import API from "./base";

const createEmailFilter = ({
  address,
  org_id,
  filter_action,
  expiration_time,
}: EmailFilter): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.post(
      `/email-filters/${org_id}/create?address=${address}&action=${filter_action}&expires=${Math.round(
        (expiration_time?.getTime() || 0) / 1000,
      )}`,
    ).then(() => resolve(), reject);
  });
};

const deleteEmailFilters = (
  organizationId: string,
  addresses: string[],
): Promise<void> => {
  return new Promise((resolve, reject) => {
    API.delete(`/email-filters/${organizationId}/delete`, {
      data: addresses,
    }).then(() => resolve(), reject);
  });
};

const getEmailFilter = (
  organizationId: string,
  address: string,
): Promise<EmailFilter> => {
  return new Promise((resolve, reject) => {
    API.get(`/email-filters/${organizationId}/query?address=${address}`).then(
      (result) => resolve(result.data.payload as EmailFilter),
      reject,
    );
  });
};

const listEmailFilters = (organizationId: string): Promise<EmailFilter[]> => {
  return new Promise((resolve, reject) => {
    API.get(`/email-filters/${organizationId}`).then(
      (result) => resolve(result.data.payload as EmailFilter[]),
      reject,
    );
  });
};

export default {
  createEmailFilter,
  deleteEmailFilters,
  getEmailFilter,
  listEmailFilters,
};
