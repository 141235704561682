import { AddCircle, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { CommonDatagridWrapper } from "../../components/elements";
import { EmailFilter } from "./model";
import { useCallback, useEffect, useState } from "react";
import { useGlobalOrganizationContext } from "../../hooks/useGlobalOrganizationContext";
import Dialog from "../../components/Dialog";
import EmailFilters from "../../api/emailFilters";
import StyledDataGrid from "../../components/elements/datagrid/StyledDataGrid";
import useIsAdmin from "../../hooks/useIsAdmin";
import {
  DataGridProps,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import DatagridToolbar from "../../components/elements/datagrid/DatagridToolbar";
import ToolbarButton from "../../components/elements/datagrid/ToolbarButton";
import { HumanReadableTimestamp } from "../../components";
import OrganizationScopedPage from "../../components/OrganizationScopedPage";
import { useAlert } from "../../lib/alert";
import { isEmail } from "class-validator";

const ManageEmailFiltersPage = () => {
  const { organization } = useGlobalOrganizationContext();
  const isAdmin = useIsAdmin();
  const [emailFilters, setEmailFilters] = useState<EmailFilter[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { handleRejectionWithError } = useAlert();
  const [dialogType, setDialogType] = useState<"CREATE" | "UPDATE">("CREATE");
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const [updatedFilter, setUpdatedFilter] = useState<EmailFilter>({
    address: "",
    org_id: organization!.id,
    filter_action: "drop",
    expiration_time: null,
  });
  const [oldExpirationTime, setOldExpirationTime] = useState<Date | null>(null);

  const columns = [
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      width: 250,
    },
    {
      field: "filter_action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ width: 1, paddingY: ".3em" }}>
            {params.row.filter_action === "drop" ? (
              <Chip color="error" label="Drop" />
            ) : (
              <Chip color="success" label="Pass" />
            )}
          </Box>
        );
      },
    },
    {
      field: "expiration_time",
      headerName: "Expires",
      sortable: true,
      filterable: false,
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        const expiration = (params.row as EmailFilter).expiration_time;

        return expiration ? (
          <HumanReadableTimestamp timestamp={expiration} format="time" />
        ) : (
          <>–</>
        );
      },
    },
  ];

  useEffect(() => {
    if (organization && !isLoaded) {
      EmailFilters.listEmailFilters(organization.id).then((filters) => {
        setEmailFilters(filters);
        setIsLoaded(true);
      });
    }
  }, [organization, isLoaded]);

  const deleteFilters = useCallback(
    (addresses: string[]) =>
      EmailFilters.deleteEmailFilters(organization!.id, addresses).then(
        () =>
          setEmailFilters(
            emailFilters.filter((f) => !addresses.includes(f.address)),
          ),
        handleRejectionWithError("Couldn't delete filter"),
      ),
    [emailFilters, organization, handleRejectionWithError],
  );

  const createOrUpdateFilter = useCallback((): Promise<void> => {
    const oldFilter =
      dialogType === "UPDATE"
        ? emailFilters.find((f) => f.address === selectionModel[0])
        : undefined;

    updatedFilter.address = updatedFilter.address.toLowerCase();

    const promises = [EmailFilters.createEmailFilter(updatedFilter)];

    if (
      oldFilter !== undefined &&
      oldFilter.address !== updatedFilter.address
    ) {
      promises.push(
        EmailFilters.deleteEmailFilters(organization!.id, [oldFilter.address]),
      );
    }

    // TODO: avoid linear scan + sort
    setEmailFilters(
      [
        ...emailFilters.filter(
          (f) =>
            f.address !== updatedFilter.address &&
            (!oldFilter || oldFilter !== f),
        ),
        updatedFilter,
      ].sort((a, b) => (a.address < b.address ? -1 : 1)),
    );

    // We don't want to resolve until both operations have settled
    // in case one operation fails, we reload, then the other operation
    // succeeds and the page immediately is displaying stale data
    return new Promise((resolve, reject) =>
      Promise.allSettled(promises).then((results) => {
        if (results.find((res) => res.status === "rejected")) {
          reject();
        } else {
          resolve();
        }
      }),
    );
  }, [emailFilters, updatedFilter, dialogType, organization, selectionModel]);

  const editBtn = (
    <ToolbarButton
      startIcon={<EditOutlined />}
      disabled={selectionModel.length !== 1}
      onClick={() => {
        setDialogType("UPDATE");
        const oldFilter = emailFilters.find(
          (f) => f.address === selectionModel[0],
        )!;
        setUpdatedFilter(oldFilter);
        setOldExpirationTime(oldFilter.expiration_time);
        setIsDialogOpen(true);
      }}
      textContent="Edit"
    />
  );

  const deleteBtn = (
    <ToolbarButton
      startIcon={<DeleteOutlined />}
      onClick={() => deleteFilters(selectionModel as string[])}
      textContent="Delete"
    />
  );

  const adminProps: Partial<DataGridProps> = {
    onRowSelectionModelChange: (newSelectionModel) => {
      setSelectionModel(newSelectionModel);
    },
    rowSelectionModel: selectionModel,
    checkboxSelection: true,
  };

  const loaded = (
    <StyledDataGrid
      slots={{ toolbar: DatagridToolbar }}
      slotProps={{
        toolbar: {
          countMsg: `${emailFilters.length} Filter${
            emailFilters.length !== 1 ? "s" : ""
          }`,
          numSelected: selectionModel.length,
          selectedBtns: isAdmin ? (
            <>
              {editBtn}
              {deleteBtn}
            </>
          ) : (
            <></>
          ),
        },
      }}
      disableRowSelectionOnClick
      disableColumnSelector
      hideFooterSelectedRowCount
      rows={emailFilters}
      pageSizeOptions={[10]}
      getRowHeight={() => "auto"}
      columns={columns}
      getRowId={(row) => row.address}
      {...(isAdmin ? adminProps : {})}
    />
  );

  return (
    <OrganizationScopedPage
      category="email-filters"
      displayOrganizationActionMenu={false}
    >
      <CommonDatagridWrapper
        isLoaded={isLoaded}
        loaded={loaded}
        title="Email Filters"
        PageHeaderProps={{
          actions: isAdmin ? (
            <Button
              variant="contained"
              onClick={() => {
                setDialogType("CREATE");
                setUpdatedFilter({
                  address: "",
                  org_id: organization!.id,
                  filter_action: "drop",
                  expiration_time: null,
                });
                setIsDialogOpen(true);
              }}
            >
              <AddCircle sx={{ mr: "0.25em" }} />
              Create Filter
            </Button>
          ) : undefined,
        }}
      />
      <Dialog
        open={isDialogOpen}
        title={`${dialogType ? "Create new" : "Edit"} filter`}
        confirmText={"Save"}
        cancelText={"Cancel"}
        disableConfirm={
          !updatedFilter.address || !isEmail(updatedFilter.address)
        }
        handleConfirm={async () => {
          setIsDialogOpen(false);
          createOrUpdateFilter().catch((e) => {
            setIsLoaded(false);
            handleRejectionWithError(
              `Failed to ${dialogType ? "create" : "update"} filter`,
            )(e);
          });
        }}
        handleCancel={() => setIsDialogOpen(false)}
      >
        <FormControl fullWidth>
          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Email address"
            type="email"
            variant="standard"
            value={!updatedFilter.address ? undefined : updatedFilter.address}
            onChange={(e) =>
              setUpdatedFilter({ ...updatedFilter, address: e.target.value })
            }
            error={!!updatedFilter.address && !isEmail(updatedFilter.address)}
          />
          <FormLabel id="filter-dialog-action-label">Action:</FormLabel>
          <RadioGroup
            aria-labelledby="filter-dialog-action-label"
            value={updatedFilter.filter_action}
            onChange={(e) =>
              setUpdatedFilter({
                ...updatedFilter,
                filter_action: e.target.value as "drop" | "pass",
              })
            }
          >
            <FormControlLabel value="drop" control={<Radio />} label="Drop" />
            <FormControlLabel value="pass" control={<Radio />} label="Pass" />
          </RadioGroup>
          <FormLabel id="filter-dialog-expiration-label">Expires:</FormLabel>
          <Select
            aria-labelledby="filter-dialog-expiration-label"
            autoFocus
            defaultValue={dialogType === "UPDATE" ? -1 : 0}
            onChange={(e) => {
              const duration = +e.target.value;
              if (duration === -1) {
                setUpdatedFilter({
                  ...updatedFilter,
                  expiration_time: oldExpirationTime,
                });
              } else {
                setUpdatedFilter({
                  ...updatedFilter,
                  expiration_time: duration
                    ? new Date(Date.now() + duration * 1000)
                    : null,
                });
              }
            }}
          >
            {dialogType === "UPDATE" && (
              <MenuItem value={-1}>Unchanged</MenuItem>
            )}
            <MenuItem value={60 * 60}>One hour</MenuItem>
            <MenuItem value={24 * 60 * 60}>One day</MenuItem>
            <MenuItem value={30 * 24 * 60 * 60}>One month</MenuItem>
            <MenuItem value={0}>Never</MenuItem>
          </Select>
        </FormControl>
      </Dialog>
    </OrganizationScopedPage>
  );
};

export default ManageEmailFiltersPage;
